.Layout {
  text-align: center;
  align-items: center;
  justify-content: top;
  color: #fff;
  background-color: #1a1c29;
  flex-direction: column;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  display: flex;
}

a:link {
  color: red;
}

a:visited {
  color: green;
}

a:hover {
  color: #ff69b4;
}

a:active {
  color: #00f;
}

.loader {
  border: 6px solid #7bb8f3;
  border-top-color: #0000;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container {
  background-color: #0000;
  flex-direction: column;
  flex: 1;
  justify-content: start;
  width: 100%;
  font-family: Kanit, sans-serif;
  display: flex;
}

.header {
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  display: flex;
}

.info-content {
  background-color: #000;
  flex-direction: column;
  flex: 1;
  place-content: center;
  align-items: center;
  padding: 40px;
  display: flex;
}

.content {
  background-color: #000;
  flex-direction: column;
  place-content: center;
  padding: 40px;
  display: flex;
}

.logo {
  width: 120px;
  height: auto;
  margin: 10px 0 10px 20px;
}

.walletContainer {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.walletBalance {
  align-items: center;
  margin: 0 10px;
  font-size: medium;
  font-weight: 600;
  display: flex;
}

.walletAddressButton {
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

.wallet {
  margin: 0 10px 0 0;
  padding: 5px;
  font-size: medium;
  font-weight: 600;
  display: flex;
}

.walletIcon {
  width: 30px;
  height: 30px;
  margin: 2px 0 2px 5px;
}

.ethIcon {
  width: 20px;
  height: 20px;
  margin: 2px;
}

.dialog {
  text-align: center;
  color: #000;
  background-color: #eee;
  width: 300px;
  margin-bottom: 27px;
  padding: 27px 18px;
}

.container h1 {
  margin: 0;
  padding-bottom: 18px;
  font-size: 18px;
}

.container h2 {
  margin: 4px;
  padding: 0;
  font-size: 15px;
}

.container span {
  padding: 5px;
}

.container form {
  background-color: #303649;
  border-radius: 16px;
  flex-direction: column;
  align-self: center;
  width: 260px;
  max-width: 436px;
  padding: 10px;
  font-size: 18px;
  display: flex;
}

.content .dialog {
  color: #fff;
  background-color: #303649;
  border-radius: 16px;
  flex-direction: column;
  align-self: center;
  width: 260px;
  max-width: 436px;
  padding: 10px;
  font-size: 18px;
  display: flex;
}

.container input {
  color: #fff;
  background-color: #181a25;
  border-style: none;
  border-radius: 16px;
  margin: 8px 0;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 600;
}

.content .dialog-title {
  margin-top: 5px;
  padding: 5px;
}

.content .dialog-sub-title {
  margin: 10px 0;
  padding: 5px;
}

.content button {
  color: #fff;
  cursor: pointer;
  background-color: #1fc7d4;
  border-style: none;
  border-radius: 16px;
  margin: 10px 0;
  padding: 9px;
  font-size: 18px;
  font-weight: 600;
}

.content .google-button {
  background-color: #e44532;
}

.content .apple-button {
  background-color: #000;
}

.container input.full-width {
  text-align: center;
  margin: 0 auto 9px;
  display: block;
}

.balance {
  word-wrap: break-word;
  align-self: center;
  max-width: 21ch;
  margin: 0 0 18px;
  padding: 0;
  font-size: 36px;
}

.info {
  word-wrap: break-word;
  background-color: #ddd;
  max-width: 21ch;
  margin: 0 auto 18px;
  padding: 12px 24px;
  font-family: Lucida Console, Monaco, monospace;
  font-size: 15px;
}

.info a {
  color: #000;
}

.generalButton {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  align-self: center;
  margin: 10px;
  padding: 5px 10px;
  font-size: 15px;
}

.callback-container {
  text-align: center;
  margin-top: 30px;
  font-size: 18pt;
}

.process-completed {
  color: green;
  margin-bottom: 10px;
  font-weight: bold;
}

.logo-image {
  width: 200px;
  height: 200px;
  margin: 10px auto;
}

.open-app-button {
  cursor: pointer;
  background-color: #21225a;
  border-radius: 5px;
  margin: 20px auto;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  color: #fff !important;
}

@media only screen and (width <= 600px) {
  .logo-image {
    width: 80%;
    height: auto;
    margin: 20px auto;
  }

  .open-app-button {
    width: 50%;
    margin-top: 20px;
    display: inline-block;
  }
}

:root {
  color-scheme: light dark;
  color: #ffffffde;
  font-synthesis: none;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background-color: #242424;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #646cff;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  font-weight: 500;
}

a:hover {
  color: #535bf2;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  cursor: pointer;
  background-color: #1a1a1a;
  border: 1px solid #0000;
  border-radius: 8px;
  padding: .6em 1.2em;
  font-family: inherit;
  font-size: 1em;
  font-weight: 500;
  transition: border-color .25s;
}

button:hover {
  border-color: #646cff;
}

button:focus, button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #fff;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d3d3d3;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

/*# sourceMappingURL=index.c2391a6e.css.map */
